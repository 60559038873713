import React from 'react';
import MovieCard from './MovieCard';

const Section = ({ title, movies }) => {
  const styles = {
    section: {
      marginBottom: '24px',
    },
    title: {
      color: '#fff',
      fontSize: '16px',
      fontWeight: 500,
      textAlign: 'left',
      lineHeight: '24px',
      margin: '0 0 12px 24px',
      fontFamily: 'Poppins',
    },
    movieList: {
      gap: '16px',
      width: '100%',
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'row',
    },
  };

  const width = window.innerWidth / 3.8;

  return (
    <div style={styles.section}>
      <div style={styles.title}>{title}</div>
      <div style={styles.movieList}>
        {movies.map(({ name, url, id }, index) => (
          <div key={id} style={{ width: '100%', display: 'flex' }}>
            {index === 0 && <div style={{ width: '24px', height: '19px' }} />}
            <MovieCard name={name} url={url} width={width} />
            {index === movies.length - 1 && (
              <div style={{ width: '24px', height: '19px' }} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Section;
