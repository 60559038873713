import React, { useState } from 'react';
import { setDoc, doc } from 'firebase/firestore';

import { db } from '../firebase';

const LoginModal = ({ onClose }) => {
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setPhoneNumber(value);
    }
  };

  const handleContinue = async () => {
    if (phoneNumber) {
      try {
        await setDoc(doc(db, 'phoneNumbers', phoneNumber), {
          phoneNumber: phoneNumber,
          timestamp: new Date(),
        });
        onClose({ numberAdded: true });
      } catch (e) {
        console.error('Error adding document: ', e);
      }
    }
  };

  const styles = {
    modalOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end', // Align items to the bottom
    },
    modalContent: {
      backgroundColor: '#1c1c1c',
      borderRadius: '16px 16px 0 0', // Rounded corners at the top
      overflow: 'hidden',
      width: '100%',
      maxWidth: '400px',
      color: '#fff',
      position: 'relative',
      padding: '24px',
      textAlign: 'center',
    },
    modalHeader: {
      fontSize: '16px',
      fontWeight: 500,
      marginBottom: '32px',
    },
    closeButton: {
      position: 'absolute',
      top: '16px',
      right: '16px',
      cursor: 'pointer',
      fontSize: '20px',
      color: 'rgba(255, 255, 255, 0.65)',
    },
    stepsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'start',
      marginBottom: '24px',
    },
    step: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: '#aaa',
    },
    activeStep: {
      color: '#ff1493',
    },
    stepCircle: {
      width: '36px',
      height: '36px',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '2px solid #aaa',
      marginBottom: '8px',
    },
    activeStepCircle: {
      borderColor: '#ff1493',
      backgroundColor: '#ff1493',
      color: '#fff',
    },
    inputFieldContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '16px',
    },
    inputPrefix: {
      padding: '11px',
      borderRadius: '8px 0 0 8px',
      border: '1px solid #555',
      backgroundColor: '#333',
      color: '#fff',
      fontSize: '16px',
      lineHeight: '20.5px',
      borderRight: 'none',
    },
    inputField: {
      width: '100%',
      padding: '12px',
      borderRadius: '0 8px 8px 0',
      border: '1px solid #555',
      backgroundColor: '#333',
      color: '#fff',
      fontSize: '16px',
    },
    continueButton: {
      width: '100%',
      padding: '12px',
      borderRadius: '8px',
      backgroundColor: '#ff1493',
      color: '#fff',
      fontSize: '16px',
      border: 'none',
      cursor: 'pointer',
      opacity: phoneNumber.length === 10 ? 1 : 0.5, // Change opacity based on input
    },
    stepsText: { fontSize: '12px', fontWeight: 400, lineHeight: '18px' },
  };

  return (
    <div style={styles.modalOverlay}>
      <div style={styles.modalContent}>
        {/* Close Button */}
        <span style={styles.closeButton} onClick={onClose}>
          &times;
        </span>
        {/* Header */}
        <div style={styles.modalHeader}>Login to Continue</div>
        {/* Steps */}
        <div style={styles.stepsContainer}>
          <div style={{ ...styles.step, ...styles.activeStep }}>
            <div style={{ ...styles.stepCircle, ...styles.activeStepCircle }}>
              1
            </div>
            <span style={styles.stepsText}>Login</span>
          </div>
          <div style={styles.step}>
            <div style={styles.stepCircle}>2</div>
            <span style={styles.stepsText}>Pay ₹25</span>
          </div>
          <div style={styles.step}>
            <div style={styles.stepCircle}>3</div>
            <span style={styles.stepsText}>Enjoy</span>
            <span style={styles.stepsText}>Watching</span>
          </div>
        </div>
        <div style={styles.inputFieldContainer}>
          <span style={styles.inputPrefix}>+91</span>
          <input
            type="text"
            placeholder="Mobile Number"
            style={styles.inputField}
            value={phoneNumber}
            onChange={handleInputChange}
            prefix="+91"
          />
        </div>
        <button
          style={styles.continueButton}
          disabled={phoneNumber.length !== 10}
          onClick={handleContinue}
        >
          CONTINUE
        </button>
      </div>
    </div>
  );
};

export default LoginModal;
