import React from 'react';
import Header from './Header';
import data from './data.json';
import Section from './Section';

const styles = {
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#1c1d1f',
  },
  innerContainer: {
    maxWidth: '600px', // Adjusted for mobile
    width: '100vw',
    overflowY: 'scroll',
    height: '100vh',
  },
};

function App() {
  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
        <Header />

        <div style={{ width: '100%' }}>
          {data.map(({ title, movies }) => (
            <div key={title}>
              <Section title={title} movies={movies} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default App;
