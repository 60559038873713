
import React from 'react';

const AlertModal = ({ onClose }) => {
  const styles = {
    modalOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalContent: {
      backgroundColor: '#1c1c1c',
      borderRadius: '16px',
      overflow: 'hidden',
      width: '100%',
      maxWidth: '400px',
      color: '#fff',
      position: 'relative',
      padding: '24px',
      textAlign: 'center',
    },
    closeButton: {
      position: 'absolute',
      top: '16px',
      right: '16px',
      cursor: 'pointer',
      fontSize: '20px',
      color: 'rgba(255, 255, 255, 0.65)',
    },
    modalHeader: {
      fontSize: '16px',
      fontWeight: 500,
      marginBottom: '32px',
    },
    modalBody: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
    },
  };

  return (
    <div style={styles.modalOverlay}>
      <div style={styles.modalContent}>
        <span style={styles.closeButton} onClick={onClose}>
          &times;
        </span>
        <div style={styles.modalHeader}>Alert Modal</div>
        <div style={styles.modalBody}>
          Thank you for providing information, We will get back to you shortly!
        </div>
      </div>
    </div>
  );
};

export default AlertModal;