import React from 'react';

import logo from '../image/logo.svg';

const Header = () => {
  const styles = {
    header: {
      width: '100%',
      textAlign: 'center',
      marginBottom: '24px',
      position: 'relative',
    },
    titleContainer: {
      width: '100%',
      padding: '24px 0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      width: '110px',
      fontWeight: 800,
      fontSize: '24px',
      lineHeight: '36px',
      letterSpacing: '10%',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      background: 'linear-gradient(90deg, #F20C89 -38.89%, #E473FF 169.19%)',
    },
    divider: {
      width: '100%',
      height: '0px',
      marginBottom: '24px',
      transform: 'rotate(-0deg)',
      border: '1px solid #FFFFFF14',
    },
    promoBanner: {
      color: '#fff',
      padding: '20px',
      overflow: 'hidden',
      borderRadius: '10px',
      position: 'relative',
      display: 'inline-block',
      background: 'linear-gradient(260.38deg, #F20C89 1.72%, #E473FF 144.05%)',
    },
    promoText: {
      fontFamily: 'Poppins',
      fontSize: '16px',
      textAlign: 'left',
    },
    image: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      width: '160px', // Adjust size as needed
      height: '100px', // Adjust size as needed
    },
    priceText: {
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
      textAlign: 'left',
      textUnderlinePosition: 'from-font',
      textDecorationSkipInk: 'none',
      color: '#FFFFFF',
    },
  };

  return (
    <header style={styles.header}>
      <div style={styles.titleContainer}>
        <img src={logo} alt="Connect" />
      </div>
      <div style={{ padding: '0 24px' }}>
        <hr style={styles.divider} />
        <div style={styles.promoBanner}>
          <div
            style={{
              fontSize: '20px',
              fontWeight: '600',
              textAlign: 'left',
              lineHeight: '30px',
              fontFamily: 'Poppins',
              textDecorationSkipInk: 'none',
              textUnderlinePosition: 'from-font',
            }}
          >
            Welcome to Cinema Chalo!
          </div>
          <div
            style={{
              maxWidth: '77%',
              display: 'flex',
              alignItems: 'flex-start',
            }}
          >
            <div style={styles.promoText}>
              Now watch India's most popular TV series in one place.
            </div>
          </div>
          <img
            src={require('../image/headerImage.png')}
            alt="Decorative"
            style={styles.image}
          />
        </div>
        <div style={{ ...styles.priceText, marginTop: '24px' }}>
          Only pay for what you want to watch!
        </div>
        <div style={{ ...styles.priceText }}>
          <span style={{ color: '#F20C89', fontFamily: 'Poppins' }}>
            @₹25 only
          </span>{' '}
          (No Subscription Needed)
        </div>
      </div>
    </header>
  );
};

export default Header;
