// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyD_OQbW2syJAkOV6IwgJnRrFwglKnCfAa0',
  authDomain: 'connect-pilot.firebaseapp.com',
  projectId: 'connect-pilot',
  storageBucket: 'connect-pilot.firebasestorage.app',
  messagingSenderId: '116551582233',
  appId: '1:116551582233:web:9da33b9fad8bab1e69e088',
  measurementId: 'G-8PHRBZ4D1R',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Firestore Database
export const db = getFirestore(app);
