import React, { useState, useEffect } from 'react';
import LoginModal from './LoginModal';
import AlertModal from './AlertModal';

const MovieCard = ({ name, url, width }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAlertModalOpen, setAlertModalOpen] = useState(false);

  const styles = {
    card: {
      width: '104px',
      height: '158px',
      cursor: 'pointer',
      overflow: 'hidden',
      borderRadius: '4px',
      transition: 'transform 0.3s',
    },
  };

  const handleClick = () => {
    setIsModalOpen(true);
    window.history.pushState(null, '', window.location.href);
  };

  useEffect(() => {
    const handlePopState = () => {
      setIsModalOpen(false);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  if (!url) {
    return <div />;
  }
  return (
    <div>
      <div onClick={handleClick}>
        <img src={url} alt={name} style={styles.card} />
      </div>
      {isModalOpen && (
        <LoginModal
          onClose={({ numberAdded }) => {
            setIsModalOpen(false);
            if (numberAdded) setAlertModalOpen(true);
          }}
        />
      )}
      {isAlertModalOpen && (
        <AlertModal onClose={() => setAlertModalOpen(false)} />
      )}
    </div>
  );
};

export default MovieCard;
